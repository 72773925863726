import { ROUTING_MAP } from '../app/shared/models/constants/routing-map';

export const environment = {
  production: false,
  localhost: false,
  listLang: ['en', 'ru', 'de'],
  unityIframeOrigin: 'https://catalog-dev2.voka.io',
  apiUrl: 'https://pathology3d-dev2.voka.io/api',
  buildHash: '-22d9f88970f749cb56417d11d9424ff84d8e0156',
  unityConfig: {
    dataUrl: '/assets/viewer-unity/Web/Build/Web.data.br',
    frameworkUrl: '/assets/viewer-unity/Web/Build/Web.framework.js.br',
    codeUrl: '/assets/viewer-unity/Web/Build/Web.wasm.br',
    streamingAssetsUrl: '/assets/viewer-unity/StreamingAssets',
    companyName: 'Innowise',
    productName: 'Pathologies',
    productVersion: '1.1.0',
    webglContextAttributes: { preserveDrawingBuffer: true },
  },
  gtmId: 'GTM-N8DRXJN',
  femaleModelLinkParams: [
    ROUTING_MAP.models,
    '949b8332-4342-47af-abec-a5ef89ccd226',
    'cc482196-1e7b-4a02-b3f8-64139b4c36ad',
    '186faf93-6e51-4fea-91e3-0ddc49195c21',
    '46afbd7a-2f99-4ca1-851b-9228c494b4ca',
  ],
  maleModelLinkParams: [
    ROUTING_MAP.models,
    '949b8332-4342-47af-abec-a5ef89ccd226',
    'cc482196-1e7b-4a02-b3f8-64139b4c36ad',
    '186faf93-6e51-4fea-91e3-0ddc49195c21',
    '10311e5a-eca5-4b2c-bc19-a5208f145700',
  ],
  chargebeeConfig: {
    site: 'vokaio-test',
    publishableKey: 'test_icdjKUw9m3CgAgDaG2botSMeIBNRgtuuP',
  },
};
